import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import HomeView2 from "../views/HomeView2.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "",
        name: "map",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/HomeView/HomeMap.vue"
          ),
      },
      {
        path: "status/setup",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/HomeView/StatusSetup.vue"
          ),
      },
      {
        path: "status/normal",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/HomeView/StatusNormal.vue"
          ),
      },
      {
        path: "status/leak/symptom",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/HomeView/StatusLeakSymptom.vue"
          ),
      },
      {
        path: "status/leak/range",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/HomeView/StatusLeakRange.vue"
          ),
      },
      {
        path: "status/check",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/HomeView/StatusCheck.vue"
          ),
      },
    ],
  },
  {
    path: "/recent",
    name: "recent",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RecentView.vue"),
  },
  {
    path: "/analysis",
    name: "analysis",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AnalysisView.vue"),
  },
  {
    path: "/statistics",
    name: "statistics",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/StatisticsView.vue"),
  },
  {
    path: "/observatory",
    name: "observatory",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ObservatoryView.vue"),
  },
  {
    path: "/sensor",
    name: "sensor",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SensorView.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/Password.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
