import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import mitt from "mitt";
import VueApexCharts from "vue3-apexcharts";

const emitter = mitt();

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

const app = createApp(App);
app.config.globalProperties.axios = axios;
app.provide("emitter", emitter);
app.use(store).use(router).use(VueApexCharts).mount("#app");
